@import url('./Fonts/index.scss');
@import './variables.scss';

body {
  margin: 0;
  padding: 0;
  font-family: 'Satoshi-Variable', sans-serif;
  background-color: $primary-color10;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-y: hidden;
}

input {
  border-color: #00000030;
  border-width: 1px;
  border-radius: 6px;
  line-height: 32px;
  width: calc(100% - 20px);
  font-family: 'Satoshi-Variable', sans-serif;
  padding: 4px 10px;
  &:focus{
    outline-color: $primary-color80;
  }
}

label {
  font-size: 14px;
  color: #000000;
}

.ListItem {
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-gap: 8px;
}

h1,h2,h3,h4,h5,h6{
  margin: 0;
  padding: 0;
}