@import '../../variables.scss';

.HoverButton {
    background: $primary-color;
    color: #FFF;
    min-height: 48px;
    border: 0;
    border-radius: 0.33rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-family: 'Satoshi', sans-serif;
    font-size: 1rem;
    padding: 0rem 1rem;
    transition: all 300ms ease;
    cursor: pointer;

    &__Outlined {
        color: $primary-color;
        background: none;
        border: 1px solid $primary-color;
    }

    &__Outlined__Light {
        color: $white-color;
        background: none;
        border: 1px solid $white-color;
    }

    &__Hover {
        transition: all ease 300ms;
    }

    &__Hovered {
        cursor: not-allowed;
    }
}