@media (max-width: 768px) {
  body {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem;
  }
}

.App {
  display: flex;
  flex-direction: column;
  gap: 16px;

  small {
    text-align: center;
    opacity: 0.5;
  }

  @media (max-width: 768px) {
    .App {
      flex-direction: row;
      justify-content: space-around;
      &__Wrapper {
        flex-direction: column;
        height: auto;
        justify-content: flex-start;
      }

      &__Left {
        .Button {
          margin-top: 1rem;
        }
      }

      &__Left,
      &__Right {
        max-width: 100%;
        padding: 16px;
        &__Content h1,
        &__Footer {
          text-align: center;
          gap: 1rem;
          padding-top: 1rem;
        }
      }
    }
  }

  &__Wrapper {
    display: flex;
    height: 50vh;
    justify-content: center;
    gap: 32px;
  }

  &__Left,
  &__Right {
    background-color: #FFFFFF;
    box-shadow: #00000010 4px 4px 4px;
    border-radius: 8px;
    padding: 24px 16px 16px 16px;
  }

  &__Left {
    max-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__Icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__Content {
      flex: 1;

      h1 {
        font-size: 24px;
        text-align: center;
      }

      p {
        text-align: center;
        font-size: 16px;
        color: #00000099;
        font-weight: 400;
      }
    }
  }

  &__Right {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__Footer {
      display: flex;
      gap: 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #00000095;
    }
  }
}
