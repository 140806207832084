$primary-color: #F05F23;
$secondary-color: #000D23;
$white-color: #FFFFFF;

$primary-color10: #F05F2310;
$primary-color20: #F05F2320;
$primary-color30: #F05F2330;
$primary-color40: #F05F2340;
$primary-color50: #F05F2350;
$primary-color60: #F05F2360;
$primary-color70: #F05F2370;
$primary-color80: #F05F2380;
$primary-color90: #F05F2390;
$primary-color100: #F05F2319;